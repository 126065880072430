import { FFmpeg } from "@ffmpeg/ffmpeg";
import { fetchFile } from "@ffmpeg/util";
import { Snackbar, SnackbarContent } from "@mui/material";
import html2canvas from "html2canvas";
import { enqueueSnackbar } from "notistack";
import React from "react";
export function capitalizeFirstWord(str) {
  return str?.charAt(0).toUpperCase() + str?.slice(1);
}
export const STORAGE_URL = "https://storage.googleapis.com/3d-container/";
export const BASEURL = "https://dashbo-432309.ue.r.appspot.com/v1/";
// export const BASEURL = "https://bayccollection.ue.r.appspot.com/v1/";
// export const BASEURL = "http://localhost:3001/v1/";
const ffmpeg = new FFmpeg();
export function showToast(type, message, setToast) {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      open={true}
      autoHideDuration={5000}
      onClose={() => setToast(false)}
    >
      <SnackbarContent
        style={{
          backgroundColor: type === "success" ? "green" : "red",
        }}
        message={message}
      />
    </Snackbar>
  );
}

export async function startRecording(
  id,
  fileName,
  setRecording,
  type,
  setPreviewContent,
  duration = 10,
  audioRef,
  isPlaying,
  setConverting,
  downloadType
) {
  setRecording(true);
  try {
    const chunks = [];
    let canvasElt = document.getElementById(id);
    let canvasElt1 = canvasElt?.firstChild;
    const videoStream = canvasElt1.captureStream();
    const audioStream = audioRef.current.captureStream();
    let combinedStream = null;
    if (isPlaying) {
      combinedStream = new MediaStream([
        ...videoStream.getVideoTracks(),
        ...audioStream.getAudioTracks(),
      ]);
    } else {
      combinedStream = new MediaStream([...videoStream.getVideoTracks()]);
    }
    const rec = new MediaRecorder(combinedStream, {
      mimeType: "video/mp4",
    });
    rec.ondataavailable = (e) => chunks.push(e.data);
    rec.onstop = async (e) => {
      const blob = new Blob(chunks, { type: "video/mp4" });
      if (type === "onlyRecord") {
        setRecording(false);
        setPreviewContent(blob);
      } else {
        await convertAndExportVid(
          blob,
          fileName,
          setRecording,
          setConverting,
          downloadType
        );
      }
    };
    rec.start();
    setTimeout(() => {
      rec.stop();
    }, duration * 1000);
  } catch (err) {
    console.log(err);
  }
}
ffmpeg.on("log", ({ type, message }) => {
  console.log({ type, message });
});

export async function convertAndExportVid(
  blob,
  fileName,
  setRecording,
  setConverting,
  downloadType
) {
  try {
    await ffmpeg.load();
    const data = await fetchFile(blob);
    await ffmpeg.writeFile("input.mp4", data);
    setRecording(false);
    setConverting(true);
    const scale = () => {
      switch (downloadType) {
        case "LowRes":
          return "scale=1280:720";
        case "HighRes":
          return "scale=1600:900";
        case "UltraHD":
          return "scale=3840:2160";
      }
    };
    await ffmpeg.exec([
      "-i",
      "input.mp4",
      "-codec",
      "copy", // Speed up conversion
      "-crf",
      "15",
      "-ar",
      "44100",
      "-c:v",
      "libx264", // H.264 codec
      "-pix_fmt",
      "yuv420p", // Ensure QuickTime compatibility
      "-vf",
      scale(), // Maintain resolution (adjust as needed)
      "-preset",
      "ultrafast", // Speed up conversion
      "-movflags",
      "faststart",
      "output.mp4",
    ]);

    // Retrieve the converted file
    const mp4Data = await ffmpeg.readFile("output.mp4");
    console.log(mp4Data);
    // Create a Blob and a download link for the converted video
    const mp4Blob = new Blob([mp4Data], { type: "video/mp4" });
    console.log(mp4Blob);

    const url = URL.createObjectURL(mp4Blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "DashBO_" + fileName + ".mp4";
    a.click();
    setConverting(false);
    URL.revokeObjectURL(url);
  } catch (err) {
    console.log(err);
    setRecording(false);
  }
}

export const validatePhoneNumber = (e) => {
  if (!e?.includes("/")) {
    return true;
  }
  if (e && e.length > 0) {
    const diff = e.split("/");
    const targetValueLength = diff[0].length;
    const requiredLength = (diff[1]?.match(/\./g) || []).length;
    if (requiredLength !== targetValueLength) {
      return false;
    } else if (targetValueLength === requiredLength) {
      return true;
    }
  }
  return false;
};

export function rgba2hex(rgbaString) {
  // Extract the RGBA components from the string
  const match = rgbaString.match(/(\d+(\.\d+)?)/g);
  if (!match || match.length < 4) {
    return null; // Invalid input
  }

  // Convert each component to an integer
  const [r, g, b] = match.map(Number);

  // Convert to hex and format the string
  const rInt = Math.round(r);
  const gInt = Math.round(g);
  const bInt = Math.round(b);
  const hexCode = `#${rInt.toString(16).padStart(2, "0")}${gInt
    .toString(16)
    .padStart(2, "0")}${bInt.toString(16).padStart(2, "0")}`;

  return hexCode;
}

export const downloadImage = (imageUrl, fileName) => {
  fetch(imageUrl)
    .then((response) => response.blob())
    .then((blob) => {
      const blobUrl = window.URL.createObjectURL(blob);

      const anchor = document.createElement("a");
      anchor.style.display = "none";
      anchor.href = blobUrl;
      anchor.download = fileName;

      document.body.appendChild(anchor);
      anchor.click();
      window.URL.revokeObjectURL(blobUrl);
      document.body.removeChild(anchor);
    });
};

export const htmlToImageConvert = async (
  name,
  elementRef,
  setFullScreen = null,
  element = "#videoAnimation"
) => {
  try {
    const data = await html2canvas(document.querySelector(element), {
      allowTaint: true,
      useCORS: true,
      scale: 4,
    });
    var anchorTag = document.createElement("a");
    anchorTag.download = "DashBO_" + name || "DashBO-NFT-preview" + ".jpg";
    anchorTag.href = data.toDataURL();
    anchorTag.target = "_blank";
    anchorTag.click();
    if (setFullScreen) {
      exitFullscreen(elementRef, setFullScreen);
    }
  } catch (err) {
    console.log(err);
  }
};

export function copyToClipboard(text) {
  if (!navigator.clipboard) {
    const textarea = document.createElement("textarea");
    textarea.value = text;
    textarea.style.position = "fixed";
    document.body.appendChild(textarea);
    textarea.focus();
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    enqueueSnackbar("Copied!", {
      variant: "success",
    });
  } else {
    // Clipboard API supported
    navigator.clipboard
      .writeText(text)
      .then(() => {
        enqueueSnackbar("Copied!", {
          variant: "success",
        });
      })
      .catch((error) => {
        console.error("Failed to copy:", error);
        alert("Failed to copy to clipboard");
      });
  }
}

export function formatNumberWithoutMeasure(value) {
  if (typeof value !== "number") {
    return value;
  }
  return value.toLocaleString();
}

export const formatNumberIntoCurrency = (num) => {
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1) + " M+";
  } else if (num >= 1000) {
    return (num / 1000).toFixed(1) + " K+";
  } else {
    return num.toString();
  }
};

export const getExpressioName = (name) => {
  switch (name) {
    case "EyeClosed":
      return "Eye Closed";
    case "X_Closed":
      return "X Closed";
    case "small_grin":
      return "Small Grin";
    case "Phoneme_vuh":
      return "Phoneme Vuh";
    case "tounge_out":
      return "Tongue Out";
    case "Phoneme_wah":
      return "Phoneme Wah";
    case "Phoneme_oH":
      return "Phoneme oH";
    case "Phoneme_ooo":
      return "Phoneme ooo";
    case "Phoneme_L":
      return "Phoneme L";
    case "Angry_Face":
      return "Angry";
    case "BloodShot":
      return "Blood Shot";
    case "Bored_Face":
      return "Bored";
    default:
      return name;
  }
};

export const handleFullscreen = (elementRef, setFullScreen) => {
  if (elementRef.current) {
    if (elementRef.current.requestFullscreen) {
      elementRef.current.requestFullscreen();
    } else if (elementRef.current.mozRequestFullScreen) {
      // Firefox
      elementRef.current.mozRequestFullScreen();
    } else if (elementRef.current.webkitRequestFullscreen) {
      // Chrome, Safari, and Opera
      elementRef.current.webkitRequestFullscreen();
    } else if (elementRef.current.msRequestFullscreen) {
      // IE/Edge
      elementRef.current.msRequestFullscreen();
    }
    setFullScreen(true);
  }
};
export const exitFullscreen = (elementRef, setFullScreen) => {
  if (elementRef.current) {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      // IE/Edge
      document.msExitFullscreen();
    }
    setFullScreen(false);
  }
};
export const allEyes = {
  Bored: [
    "Scumbag",
    "Bored",
    "Sleepy",
    "3d",
    "Heart",
    "Sunglasses",
    "Robot",
    "Bloodshot",
    "Blindfold",
    "Laser Eyes",
    "Holographic",
    "Cyborg",
    "Blue Beams",
    "Zombie",
    "Eyepatch",
  ],
  Sad: ["Sad"],
  EyeClosed: ["Closed", "Coins", "X Eyes"],
  Angry: ["Angry"],
  Hypnotized: ["Hypnotized", "Wide Eyed", "Crazy"],
};
export const cleanJsonValues = (singleObject) => {
  const cleanedObject = {};
  for (const key in singleObject) {
    if (singleObject.hasOwnProperty(key)) {
      const value = singleObject[key].replace(/\s+/g, " ").trim();
      cleanedObject[key] = value;
    }
  }
  return cleanedObject;
};

export function toTitleCase(str) {
  return str
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt().toLowerCase())
    .join(" ");
}
