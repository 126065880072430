import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { SnackbarProvider } from "notistack";
import { Suspense, lazy } from "react";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import { Loader } from "./components";
import { LoaderProvider } from "./context/loaderContext";
import GiftBox from "./pages/giftBox";
import NftModelDetailsNew from "./pages/nftModelDetailsNew";
import Profile from "./pages/profile";
import store from "./store";
import { AuthProvider, useAuth } from "./context/authContext";
import { themeSettings } from "./theme";
import { GlobalTheme } from "./theme/GlobalTheme";
import CollectionData from "./pages/CollectionData/projects";
import EmptyPage from "./pages/EmptyPage";
import NotFound from "./pages/notfound/NotFound";
import Model3d from "./components/Menus/model3d";
import ImageTextureUploader from "./components/ImageTextureUploader/ImageTextureUploader";
import BaycRenders from "./pages/baycRenders";
const Test = lazy(() => import("./pages/Test"));

const ThirdPartyImageEditor = lazy(() => import("./pages/editor/test"));
const AIGenerator = lazy(() => import("./pages/AIGenerator"));
const Creator = lazy(() =>
  import("./components/Menus/dashboard3d/modelCreator")
);
// const FaceFilterAR = lazy(() => import("./pages/FaceFilterAR"));
const LandingPage = lazy(() => import("./pages/landingPage"));
const CompanyProjects = lazy(() => import("./pages/company/projects"));
const Projects = lazy(() => import("./pages/projects"));
const ProjectDetails = lazy(() => import("./pages/projectDetails"));
const NftDetails = lazy(() => import("./pages/nftDetails"));
const Dashboard3d = lazy(() => import("./components/Menus/dashboard3d"));
const MetaGameVR = lazy(() => import("./components/Menus/metaGameVR"));
const DTools = lazy(() => import("./components/Menus/2DTools"));
const ARService = lazy(() => import("./components/Menus/ARService"));
const About = lazy(() => import("./pages/about/about"));
const Tokenomics = lazy(() => import("./pages/tokenomics/tokenomics"));
const Roadmap = lazy(() => import("./pages/roadmap/roadmap"));
const Analytics = lazy(() => import("./components/Menus/analytics"));
const Login = lazy(() => import("./components/Login"));

const Animation = lazy(() =>
  import("./components/Menus/dashboard3d/animation/animation")
);
const ModelEditor = lazy(() =>
  import("./components/Menus/dashboard3d/modeleditor/index")
);
const MyAccounts = lazy(() => import("./pages/myAccount"));
const Tutorials = lazy(() => import("./components/Menus/tutorials"));
const Chatbot = lazy(() => import("./components/Menus/Chatbot"));
const SocialShare = lazy(() => import("./components/SocialShare/socialShare"));
const Faq = lazy(() => import("./pages/faq/faq"));
const Features = lazy(() => import("./pages/features/features"));
const Pricing = lazy(() => import("./pages/pricing/pricing"));
const ContactUs = lazy(() => import("./pages/contactUs"));
const Gaming = lazy(() => import("./pages/gaming/Gaming"));

function App() {
  const helmetContext = {};

  return (
    <HelmetProvider context={helmetContext}>
      <Suspense fallback={<Loader />}>
        <LoaderProvider>
          <ThemeProvider theme={themeSettings}>
            <GlobalTheme theme={themeSettings} />
            <SnackbarProvider
              autoHideDuration={3000}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <CssBaseline />
              <Provider store={store}>
                <BrowserRouter>
                  <AuthProvider>
                    <Routes>
                      <Route path="/login" element={<Login />} />
                      <Route path="/render" element={<BaycRenders />} />
                      <Route
                        path="/:clientId/collection/:id"
                        element={<CollectionData />}
                      />
                      <Route
                        path="/:clientId/collection"
                        element={<EmptyPage />}
                      />
                      <Route path="/faq" element={<Faq />} />
                      <Route path="/spaaceBox" element={<GiftBox />} />

                      <Route path="/features" element={<Features />} />
                      <Route path="/pricing" element={<Pricing />} />
                      <Route
                        path="/:clientId/:id/:nftId"
                        element={<NftModelDetailsNew />}
                      />
                      <Route path="/modeleditor" element={<ModelEditor />} />
                      <Route path="/myAccounts" element={<MyAccounts />} />
                      <Route
                        path="/:clientId/:id/:nftId/3dTools"
                        element={<Dashboard3d />}
                      />

                      <Route
                        path="/:clientId/:id/:nftId/dashboard3d"
                        element={<Animation />}
                      />
                      <Route path="/imagePreview" element={<SocialShare />} />
                      <Route
                        path="/:clientId/:id/:nftId/dashboard3D/editor"
                        element={<ModelEditor />}
                      />
                      <Route
                        path="/:clientId/:id/:nftId/3dTools/editor"
                        element={<ModelEditor />}
                      />
                      <Route
                        path="/:clientId/:id/:nftId/3dTools/merch"
                        element={<Creator />}
                      />
                      <Route
                        path="/:clientId/:id/:nftId/3dTools/MBACloset"
                        element={<Creator />}
                      />
                      {/* <Route
                      path="/:clientId/:id/:nftId/3dTools/traitSwapper"
                      element={<Creator />}
                    /> */}
                      <Route
                        path="/:clientId/:id/:nftId/2DTools/imageEditor"
                        element={<ThirdPartyImageEditor />}
                      />
                      <Route
                        path="/:clientId/:id/:nftId/2DTools/AiGenerated"
                        element={<AIGenerator />}
                      />

                      <Route
                        path="/:clientId/:id/:nftId/files"
                        element={<MetaGameVR />}
                      />
                      <Route
                        path="/:clientId/:id/:nftId/2DTools"
                        element={<DTools />}
                      />
                      <Route path="/ddu" element={<Model3d />} />
                      <Route path="/ddx" element={<ImageTextureUploader />} />
                      {/* <Route path="/FaceFilterAR" element={<FaceFilterAR />} />

                    <Route
                      path="/:clientId/:id/:nftId/brandMaker"
                      element={<BrandMaker />}
                    />
                    <Route
                      path="/:clientId/:id/:nftId/characterBuilder"
                      element={<StoryBuilder />}
                    />

                    <Route path="/model3d/:slug" element={<Model3d />} /> */}
                      <Route
                        path="/:clientId/:id/:nftId/chatbot"
                        element={<Chatbot />}
                      />
                      <Route
                        path="/:clientId/:id/:nftId/arTools"
                        element={<ARService />}
                      />
                      {/* <Route
                      path="/:clientId/:id/:nftId/NFTvoiceassistant"
                      element={<NFTvoiceassistant />}
                    />
                    <Route
                      path="/:clientId/:id/:nftId/chatbot"
                      element={<Chatbot />}
                    />

                    <Route
                      path="/:clientId/:id/:nftId/streamCompanion"
                      element={<StreamCompanion />}
                    />
                    <Route
                      path="/:clientId/:id/:nftId/merchandise"
                      element={<Merchandise />}
                    /> */}
                      <Route
                        path="/:clientId/:id/:nftId/analytics"
                        element={<Analytics />}
                      />
                      <Route
                        path="/:clientId/:id/:nftId/tutorials"
                        element={<Tutorials />}
                      />

                      <Route path="/projects" element={<Projects />} />
                      <Route path="/contact" element={<About />} />
                      <Route path="/tokenomics" element={<Tokenomics />} />
                      <Route path="/roadmap" element={<Roadmap />} />
                      <Route path="/profile" element={<Profile />} />
                      <Route path="/games/:gameName" element={<Gaming />} />

                      <Route path="/contactUs" element={<ContactUs />} />
                      <Route path="/test" element={<Test />} />

                      <Route path="/project/:id" element={<ProjectDetails />} />
                      <Route path="*" element={<ProtectedRoutes />} />
                      <Route path="404" element={<NotFound />} />
                      {/* <Route path="*" element={<NotFound />} /> */}
                    </Routes>
                  </AuthProvider>
                </BrowserRouter>
              </Provider>
            </SnackbarProvider>
          </ThemeProvider>
        </LoaderProvider>
      </Suspense>
    </HelmetProvider>
  );
}

function ProtectedRoutes() {
  const { user, loading } = useAuth();
  const location = useLocation();

  if (loading) {
    return <Loader home />;
  }

  if (!user) {
    // Save the current path before redirecting to login
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return (
    <Routes>
      <Route path="*" element={<NotFound />} />
      <Route path="/" element={<LandingPage />} />
      <Route path="/:clientId" element={<CompanyProjects />} />
      <Route path="/:clientId/:id" element={<NftDetails />} />
    </Routes>
  );
}

export default App;
