// MobileFooter.jsx

import React from "react";
import { Box, Typography } from "@mui/material";
import Logo from "../assets/images/Logo-inceptive-white.png";
import { FaTiktok, FaDiscord } from "react-icons/fa";
import { AiFillYoutube, AiOutlineInstagram } from "react-icons/ai";
import { FaXTwitter } from "react-icons/fa6";
import { useTheme } from "@emotion/react";
import { Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  apeCoinLogo,
  madeByApeLogo,
  madeByUnityLogo,
} from "../staticData/images";

const MobileFooter = () => {
  const { palette, ...theme } = useTheme();
  const navigate = useNavigate();
  const redirectToHome = () => {
    navigate("/");
  };

  return (
    <Box
      sx={{
        background: "#000",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: 0,
          margin: 0,
        }}
      >
        <Box
          onClick={redirectToHome}
          sx={{
            padding: "8px",
          }}
        >
          <img
            src={Logo}
            height="50px"
            alt="Logo"
            sx={{
              padding: "8px",
              boxShadow: "5px 5px 5px rgba(149, 149, 149, 0.44)",
            }}
          />
        </Box>
        <Box>
          <AiFillYoutube
            color={palette.font.default}
            style={{
              cursor: "pointer",
              marginRight: "1vw",
              fontSize: "2em",
              backgroundColor: "rgba(149, 149, 149, 0.30)",
            }}
            size={"20px"}
          />

          <FaTiktok
            color={palette.font.default}
            style={{
              cursor: "pointer",
              marginRight: "2vw",
              boxShadow: "10px 10px 10px rgba(149, 149, 149, 0.22)",
            }}
            size={"20px"}
          />
          <AiOutlineInstagram
            color={palette.font.default}
            style={{
              cursor: "pointer",
              marginRight: "2vw",
              boxShadow: "10px 10px 10px rgba(149, 149, 149, 0.22)",
            }}
            size={"20px"}
          />
          <FaDiscord
            color={palette.font.default}
            size={"20px"}
            style={{
              cursor: "pointer",
              marginRight: "2vw",
              boxShadow: "10px 10px 10px rgba(149, 149, 149, 0.22)",
            }}
          />
          <FaXTwitter
            color={palette.font.default}
            size={"20px"}
            style={{
              cursor: "pointer",
              marginRight: "2vw",
              boxShadow: "10px 10px 10px rgba(149, 149, 149, 0.22)",
            }}
          />
        </Box>
      </Box>
      <Divider
        sx={{
          border: "1px solid #C4C4C4",
          opacity: 0.3,
        }}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box sx={{ padding: "4px" }}>
          <img src={madeByUnityLogo} height="25px" alt="Made by Unity" />
        </Box>
        <Box sx={{ padding: "4px" }}>
          <img src={madeByApeLogo} height="25px" alt="Made by Ape" />
        </Box>
        <Box sx={{ padding: "4px" }}>
          <img src={apeCoinLogo} height="25px" alt="Ape Coin" />
        </Box>
      </Box>
      <Divider
        sx={{
          border: "1px solid #C4C4C4",
          opacity: 0.3,
        }}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "8px", // Adjust the padding as needed
          background: "#000",
        }}
      >
        <Typography
          sx={{
            display: "flex",
            gap: "8px", // Increased gap for better spacing
            color: "#fff",
            fontFamily: "Inter",
            fontSize: "11px", // Increased font size
            fontWeight: 500, // Increased font weight for a bolder look
            letterSpacing: "0.02em", // Slightly increased letter spacing
            textAlign: "left",
          }}
        >
          <a
            href="#"
            style={{
              textDecoration: "none",
              fontFamily: "var(--font-i_r)",
              color: "rgba(255, 255, 255, 0.4)", // Set the color to white
            }}
          >
            FAQ
          </a>
          <span
            style={{
              color: "rgba(255, 255, 25, 0.4)",
            }}
          >
            |
          </span>
          <a
            href="#"
            style={{
              textDecoration: "none",
              fontFamily: "var(--font-i_r)",
              color: "rgba(255, 255, 255, 0.4)", // Set the color to white
            }}
          >
            Terms
          </a>
          <span
            style={{
              color: "rgba(255, 255, 25, 0.4)",
            }}
          >
            |
          </span>
          <a
            href="#"
            style={{
              textDecoration: "none",
              color: "rgba(255, 255, 255, 0.4)", // Set the color to white
              fontFamily: "var(--font-i_r)",
            }}
          >
            Privacy
          </a>
        </Typography>
        <Typography
          sx={{
            color: "rgba(255, 255, 255, 0.4)",
            fontFamily: "Inter",
            fontSize: "9px",
            fontWeight: 400,
            lineHeight: "14px",
            letterSpacing: "0em",
            textAlign: "left",
            fontFamily: "var(--font-i_r)",
          }}
        >
          © 2023 Inceptive Studio All rights reserved.
        </Typography>
      </Box>
    </Box>
  );
};

export default MobileFooter;
