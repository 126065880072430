import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
const NotFound = () => {
  const navigate = useNavigate();
  const redirectHome = () => {
    navigate("/");
  };
  return (
    <Box marginTop="10%">
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <img
          src="https://storage.googleapis.com/3d-container/404.png"
          alt="NOT Found"
          style={{
            height: "auto",
            width: "30%",
          }}
        />
        <Typography
          sx={{
            fontFamily: "var(--font-r_r)",
            fontSize: { xl: "45px", lg: "42px" },
            fontWeight: "700",
            lineHeight: "120px",
            textAlign: "left",
            color: "#FFF !important",
          }}
        >
          PAGE NOT FOUND
        </Typography>

        <Button
          variant="outlined"
          sx={{
            backgroundColor: "#ffb800 !important",
            color: "#000 !important",
            width: { xl: "16%", lg: "26%", sm: "100%" },
            height: { xl: "auto", lg: "auto", sm: "100%" },
            fontSize: "23px",
            fontWeight: "700",
          }}
          onClick={redirectHome}
        >
          Go back
        </Button>
      </Box>
    </Box>
  );
};
export default NotFound;
