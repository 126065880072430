export const quoteProjectBackground =
  "https://ishrostorage.blob.core.windows.net/container-3d/Rectangle%20251.png";
export const apeCoinLogo =
  "https://ishrostorage.blob.core.windows.net/container-3d/Powered%20By%20Ape%20Coin%20White.png";
export const madeByApeLogo =
  "https://ishrostorage.blob.core.windows.net/container-3d/BY%20APES%20LICENSE%2069%20White%201.png";
export const otherDexLogo =
  "https://storage.googleapis.com/3d-container/Poweredbyotherdexlogo.png";
export const madeByUnityLogo =
  "https://ishrostorage.blob.core.windows.net/container-3d/unity_logo.png";
export const homeBanner =
  "https://ishrostorage.blob.core.windows.net/container-3d/MAYC%20banner.png";

export const homeFooterInceptive =
  "https://ishrostorage.blob.core.windows.net/container-3d/MAYC%20banner.png";
export const apeCoinOnlyLogo =
  "https://ishrostorage.blob.core.windows.net/container-3d/apeCoinLogo.png";
export const Logo =
  "https://ishrostorage.blob.core.windows.net/container-3d/Logo.png";
export const LogoBC =
  "https://storage.googleapis.com/3d-container/DashboBCLogo.png";

// Dimension Template Panel

export const dimensionTC =
  "https://ishrostorage.blob.core.windows.net/container-3d/dimension-tc.jpg";
export const dimensionTP =
  "https://ishrostorage.blob.core.windows.net/container-3d/dimension-tp.jpg";
export const dimensionTPRO =
  "https://ishrostorage.blob.core.windows.net/container-3d/dimension-tpro.jpg";
export const dimensionFD =
  "https://ishrostorage.blob.core.windows.net/container-3d/dimension-fd.jpg";
export const dimensionFP =
  "https://ishrostorage.blob.core.windows.net/container-3d/dimension-fp.jpg";
export const dimensionFPRO =
  "https://ishrostorage.blob.core.windows.net/container-3d/dimension-fpro.jpg";
export const dimensionIP =
  "https://ishrostorage.blob.core.windows.net/container-3d/dimension-ip.jpg";
export const dimensionIS =
  "https://ishrostorage.blob.core.windows.net/container-3d/dimension-is.jpg";
export const dimensionISQU =
  "https://ishrostorage.blob.core.windows.net/container-3d/dimension-isqu.jpg";

// Crypto Panel Coin

export const coin1 =
  "https://ishrostorage.blob.core.windows.net/container-3d/coin1.svg";
export const coin2 =
  "https://ishrostorage.blob.core.windows.net/container-3d/coin2.svg";
export const coin3 =
  "https://ishrostorage.blob.core.windows.net/container-3d/coin3.svg";
export const coin4 =
  "https://ishrostorage.blob.core.windows.net/container-3d/coin4.svg";

// Sticker Panel

export const sticker1 =
  "https://ishrostorage.blob.core.windows.net/container-3d/sticker1.svg";
export const sticker2 =
  "https://ishrostorage.blob.core.windows.net/container-3d/sticker2.svg";
export const sticker3 =
  "https://ishrostorage.blob.core.windows.net/container-3d/sticker3.svg";
export const sticker4 =
  "https://ishrostorage.blob.core.windows.net/container-3d/sticker4.svg";
export const sticker5 =
  "https://ishrostorage.blob.core.windows.net/container-3d/sticker5.svg";
export const sticker6 =
  "https://ishrostorage.blob.core.windows.net/container-3d/sticker6.svg";
export const sticker7 =
  "https://ishrostorage.blob.core.windows.net/container-3d/sticker7.svg";
export const sticker8 =
  "https://ishrostorage.blob.core.windows.net/container-3d/sticker8.svg";
export const sticker9 =
  "https://ishrostorage.blob.core.windows.net/container-3d/sticker9.svg";

export const footerApe = "https://storage.googleapis.com/3d-container/ape.png";
export const footerMade =
  "https://storage.googleapis.com/3d-container/made.png";
export const footerOtherdex =
  "https://storage.googleapis.com/3d-container/otherdex.png";
export const footerUnity =
  "https://storage.googleapis.com/3d-container/unity.png";
