import { useTheme } from "@emotion/react";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  LinearProgress,
  Typography,
  checkboxClasses,
  linearProgressClasses,
  styled,
} from "@mui/material";
import * as React from "react";
import FooterHome from "../../footer/FooterHome";
import Header from "../../Header";
import { Unity, useUnityContext } from "react-unity-webgl";
import BackButton from "../../BackButton";
import { useNavigate } from "react-router-dom";
import ModelTool from "../dashboard3d/ModelTool";
import { GiShadowFollower } from "react-icons/gi";
import { exitFullscreen, handleFullscreen } from "../../../utils";
import { BiExport } from "react-icons/bi";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: "10px",

  width: "60%",
  borderRadius: 3,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 3,
    backgroundColor: "#FFB800",
  },
}));

function Model3d() {
  const { palette, ...theme } = useTheme();
  const navigate = useNavigate();
  const [shaders, setShaders] = React.useState(null);
  const [isCharacter, setIsCharacter] = React.useState(true);

  const [fullScreen, setFullScreen] = React.useState(false);
  const elementRef = React.useRef(null);

  const [loadingName, setLoadingName] = React.useState(
    "Gathering intergalactic building blocks..."
  );
  const unity = useUnityContext({
    loaderUrl:
      "https://7ef40650-26ac-4365-9a53-56ef81e33968.client-api.unity3dusercontent.com/client_api/v1/environments/production/buckets/9cb1e65b-e3d7-42c9-8b7c-cb368eb4b2bd/release_by_badge/latest/entry_by_path/content/?path=FarAway.loader.js",
    frameworkUrl:
      "https://7ef40650-26ac-4365-9a53-56ef81e33968.client-api.unity3dusercontent.com/client_api/v1/environments/production/buckets/9cb1e65b-e3d7-42c9-8b7c-cb368eb4b2bd/release_by_badge/latest/entry_by_path/content/?path=FarAway.framework.js.unityweb",
    dataUrl:
      "https://7ef40650-26ac-4365-9a53-56ef81e33968.client-api.unity3dusercontent.com/client_api/v1/environments/production/buckets/9cb1e65b-e3d7-42c9-8b7c-cb368eb4b2bd/release_by_badge/latest/entry_by_path/content/?path=FarAway.data.unityweb",
    codeUrl:
      "https://7ef40650-26ac-4365-9a53-56ef81e33968.client-api.unity3dusercontent.com/client_api/v1/environments/production/buckets/9cb1e65b-e3d7-42c9-8b7c-cb368eb4b2bd/release_by_badge/latest/entry_by_path/content/?path=FarAway.wasm.unityweb",
    // loaderUrl: "/webgl/FarAway.loader.js",
    // frameworkUrl: "/webgl/FarAway.framework.js.unityweb",
    // dataUrl: "/webgl/FarAway.data.unityweb",
    // codeUrl: "/webgl/FarAway.wasm.unityweb",
    webglContextAttributes: {
      preserveDrawingBuffer: true,
    },
  });
  React.useEffect(() => {
    let percentProgress = unity?.loadingProgression * 100;
    if (percentProgress > 0 && percentProgress < 20) {
      setLoadingName(
        "Counting polygons, one by one (seriously, there are a lot)..."
      );
    }
    if (percentProgress > 20 && percentProgress < 40) {
      setLoadingName("Warming up our virtual 3D printing press...");
    }
    if (percentProgress > 40 && percentProgress < 60) {
      setLoadingName("Summoning the 3D gnomes to do their thing...");
    }
    if (percentProgress > 60 && percentProgress < 80) {
      setLoadingName(
        "Rounding up all the lost 3D sheep... just kidding, it's code!"
      );
    }
    if (percentProgress > 80 && percentProgress < 100) {
      setLoadingName("Holding back impatient 3D squirrels - they're nuts!");
    }
  }, [unity?.loadingProgression]);
  const bikeColors = [
    {
      name: "1",
      type: "A",
      img: "https://storage.googleapis.com/3d-container/bikeModel/A1.png",
      selected: false,
    },
    {
      name: "2",
      type: "A",
      img: "https://storage.googleapis.com/3d-container/bikeModel/A2.png",
      selected: false,
    },
    {
      name: "3",
      type: "A",
      img: "https://storage.googleapis.com/3d-container/bikeModel/A3.png",
      selected: false,
    },
    {
      name: "4",
      type: "A",
      img: "https://storage.googleapis.com/3d-container/bikeModel/A4.png",
      selected: false,
    },
    {
      name: "1",
      type: "B",
      img: "https://storage.googleapis.com/3d-container/bikeModel/B1.png",
      selected: false,
    },
    {
      name: "2",
      type: "B",
      img: "https://storage.googleapis.com/3d-container/bikeModel/B3.png",
      selected: false,
    },
    {
      name: "3",
      type: "B",
      img: "https://storage.googleapis.com/3d-container/bikeModel/B2.png",
      selected: false,
    },
    {
      name: "1",
      type: "C",
      img: "https://storage.googleapis.com/3d-container/bikeModel/C1.png",
      selected: false,
    },
    {
      name: "2",
      type: "C",
      img: "https://storage.googleapis.com/3d-container/bikeModel/B2.png",
      selected: false,
    },
    {
      name: "3",
      type: "C",
      img: "https://storage.googleapis.com/3d-container/bikeModel/A4.png",
      selected: false,
    },
  ];
  const [panels, setPanels] = React.useState([
    {
      name: "Vehicles",
      selected: true,
      type: "ChangeBike",
      icon: "https://storage.googleapis.com/3d-container/bikeModel/bike.png",
      varaints: [
        {
          name: "A",
          img: "https://storage.googleapis.com/3d-container/bikeModel/Bike1.png",
          selected: true,
        },
        {
          name: "B",
          img: "https://storage.googleapis.com/3d-container/bikeModel/Bike2.png",
          selected: false,
        },
        {
          name: "C",
          img: "https://storage.googleapis.com/3d-container/bikeModel/Bike3.png",
          selected: false,
        },
      ],
    },
    {
      name: "Colors",
      selected: false,
      type: "ChangeBike",
      icon: "https://storage.googleapis.com/3d-container/bikeModel/color.png",
      varaints: [],
    },
    {
      name: "Stickers",
      selected: false,
      type: "ChangeStickers",
      icon: "https://storage.googleapis.com/3d-container/bikeModel/sticker.png",
      varaints: [
        {
          name: "Ape",
          img: "https://storage.googleapis.com/3d-container/bikeModel/stickerCircle.png",
          selected: false,
        },
        {
          name: "Null",
          img: "https://storage.googleapis.com/3d-container/bikeModel/nosticker.png",
          selected: false,
        },
      ],
    },
    // {
    //   name: "Files",
    //   selected: false,
    //   icon: "https://storage.googleapis.com/3d-container/bikeModel/files.png",
    //   varaints: [
    //     {
    //       name: "A",
    //       img:"",
    //       selected: false,
    //     },
    //     {
    //       name: "B",
    //       img:"",
    //       selected: false,
    //     },
    //     {
    //       name: "C",
    //       img:"",
    //       selected: false,
    //     },
    //   ],
    // },
  ]);
  const handleSelectShader = (shader) => {
    setShaders(shader);
  };
  React.useEffect(() => {
    const selected = panels?.find((e) => e.selected);
    if (unity?.isLoaded && selected?.varaints?.find((e) => e.selected)?.name) {
      console.log(
        "Manager",
        selected.type,
        selected?.varaints?.find((e) => e.selected)?.name
      );
      unity?.sendMessage(
        "Manager",
        selected.type,
        selected?.name === "Stickers"
          ? selected?.varaints?.find((e) => e.selected)?.name
          : selected?.varaints?.find((e) => e.selected)?.name + " " + 1
      );
    }
  }, [unity?.isLoaded, panels]);
  React.useEffect(() => {
    if (unity?.isLoaded && shaders) {
      unity?.sendMessage("Manager", "ChangeShaders", shaders);
    }
  }, [unity?.isLoaded, shaders]);
  React.useEffect(() => {
    if (unity?.isLoaded) {
      unity?.sendMessage(
        "Manager",
        "ChangeCharacters",
        isCharacter ? "A" : "Null"
      );
    }
  }, [unity?.isLoaded, isCharacter]);
  const RenderItems = ({ items }) => {
    const selectedVehicle = panels[0]?.varaints?.find((e) => e.selected);
    return (
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {items?.name === "Colors"
            ? bikeColors
                ?.filter((e) => e.type === selectedVehicle?.name)
                ?.map((item) => {
                  return (
                    <Box
                      onClick={() => {
                        unity?.sendMessage(
                          "Manager",
                          "ChangeBike",
                          selectedVehicle?.name + " " + item?.name
                        ); // const updated = bikeColors
                        //   ?.filter((e) => e.type === selectedVehicle?.name)
                        //   ?.map((it) => {
                        //     if (it?.name === item?.name)
                        //       return {
                        //         ...it,
                        //         selected: true,
                        //       };
                        //     return {
                        //       ...it,
                        //       selected: false,
                        //     };
                        //   });
                        // console.log(updated);
                        // setPanels(() =>
                        //   panels?.map((panel) => {
                        //     if (panel?.name === items.name)
                        //       return {
                        //         ...panel,
                        //         varaints: updated,
                        //       };
                        //     return {
                        //       ...panel,
                        //     };
                        //   })
                        // );
                      }}
                      sx={{
                        cursor: "pointer",
                        // border: "1px solid #474747",
                        margin: "10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "0.8vw",
                        // "&:hover": {
                        //   border: "1px solid #FFB800",
                        // },
                      }}
                    >
                      <Avatar
                        sx={{
                          width: {
                            xs: "16vw",
                            lg: "5vw",
                            xl: "5vw",
                            md: "150px",
                            sm: "50px",
                          },
                          height: {
                            xs: "16vw",
                            lg: "5vw",
                            xl: "5vw",
                            md: "150px",
                            sm: "50px",
                          },
                          borderRadius: "0.8vw",
                        }}
                        variant="square"
                        src={item.img}
                        alt={item.id}
                      />
                    </Box>
                  );
                })
            : items?.varaints?.map((item) => {
                return (
                  <Box
                    onClick={() => {
                      const updated = items?.varaints?.map((it) => {
                        if (it?.name === item?.name)
                          return {
                            ...it,
                            selected: true,
                          };
                        return {
                          ...it,
                          selected: false,
                        };
                      });
                      console.log(updated);
                      setPanels(() =>
                        panels?.map((panel) => {
                          if (panel?.name === items.name)
                            return {
                              ...panel,
                              varaints: updated,
                            };
                          return {
                            ...panel,
                          };
                        })
                      );
                    }}
                    sx={{
                      cursor: "pointer",
                      // border: "1px solid #474747",
                      // padding: "15px",
                      margin: "10px",
                      borderRadius: "0.8vw",
                      // "&:hover": {
                      //   border: "1px solid #FFB800",
                      // },
                    }}
                  >
                    <Avatar
                      sx={{
                        width: {
                          xs: "16vw",
                          lg: "5vw",
                          xl: "5vw",
                          md: "150px",
                          sm: "50px",
                        },
                        height: {
                          xs: "16vw",
                          lg: "5vw",
                          xl: "5vw",
                          md: "150px",
                          sm: "50px",
                        },
                        borderRadius: "0.8vw",
                      }}
                      variant="square"
                      src={item.img}
                      alt={item.id}
                    />

                    {/* <Typography
                      sx={{
                        fontFamily: "var(--font-i_r)",
                        textAlign: "center",

                        fontSize: {
                          xl: "1em",
                          lg: "0.9em",
                          md: "18px",
                        },
                      }}
                    >
                      {item?.name}
                    </Typography> */}
                  </Box>
                );
              })}
          {items?.name === "Stickers" && (
            <Box
              sx={{
                cursor: "pointer",
                // border: "1px solid #474747",
                // padding: "15px",
                margin: "10px",
                borderRadius: "0.8vw",
                // "&:hover": {
                //   border: "1px solid #FFB800",
                // },
              }}
            >
              <Button
                onClick={() => {
                  unity?.sendMessage("Manager", "UploadTexture");
                }}
              >
                Upload Sticker
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    );
  };
  return (
    <Box
      sx={{
        backgroundPosition: "bottom",
        backgroundSize: "cover",
      }}
    >
      <Header hamburger={true} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          // alignItems: "",
          height: "100vh",
          width: "100vw",
        }}
      >
        <Box
          sx={{
            width: "96%",
            marginTop: "5vw",
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
            height: "82%",
            borderRadius: "0.8vw",
            backdropFilter: "blur(16px)",
          }}
        >
          {!unity?.isLoaded && (
            <Box
              sx={{
                width: "80%",
                position: "absolute",
                display: unity?.isLoaded ? "none" : "flex",
                height: "70vh",
                alignItems: "center",
                // margin: "auto",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "800",
                  fontFamily: "var(--font-f_r)",
                  fontSize: "1vw",
                  mb: 2,
                  width: "100%",
                  textAlign: "center",
                  textTransform: "capitalize",
                  color: "#C4C4C4",
                }}
              >
                {loadingName}
              </Typography>
              <BorderLinearProgress
                variant="determinate"
                value={unity?.loadingProgression * 100}
              />
            </Box>
          )}
          <Box
            onClick={() => navigate(-1)}
            sx={{ position: "absolute", top: 0, left: 0, margin: "20px" }}
          >
            <BackButton />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "70vh",
              width: "15%",
            }}
          >
            {panels.map((panel) => {
              return (
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    background: "#121212",
                    cursor: "pointer",
                    border: panel?.selected ? "1px solid #ffb800" : "",
                    borderRadius: "0.5vw",
                    my: 2,
                    px: 1,
                    py: 2,
                  }}
                  onClick={() => {
                    const updated = panels.map((pa) => {
                      if (panel.name === pa.name)
                        return {
                          ...pa,
                          selected: true,
                        };
                      return {
                        ...pa,
                        varaints: pa.name === "Colors" ? [] : pa?.varaints,
                        selected: false,
                      };
                    });
                    setPanels(updated);
                  }}
                >
                  <img
                    style={{ marginInline: "0.8vw", width: "10%" }}
                    src={panel.icon}
                  />
                  <Typography sx={{ color: "#fff", fontSize: "1vw" }}>
                    {panel.name}
                  </Typography>
                </Box>
              );
            })}
          </Box>
          <Box sx={{ marginInline: "0.8vw" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBlock: "1.5vh",
              }}
            >
              <FormControlLabel
                value={isCharacter}
                sx={{
                  color: "#ffb800",
                  fontSize: "1vw",
                }}
                control={
                  <Checkbox
                    size="large"
                    onChange={() => setIsCharacter(!isCharacter)}
                    checked={isCharacter}
                    sx={{
                      [`&, &.${checkboxClasses.checked}`]: {
                        color: "#ffb800",
                      },
                    }}
                  />
                }
                label="Enable Character"
                labelPlacement="end"
              />
              <Box
                sx={{
                  display: "flex",
                }}
              >
                <ModelTool
                  icon={GiShadowFollower}
                  shaders={[
                    { key: "Default Shader", value: "DefaultShader" },
                    { key: "Toon Shader", value: "ToonShader" },
                  ]}
                  onSelectShader={handleSelectShader}
                  name="choose shader"
                />
                <Box sx={{ marginLeft: "1vw" }}>
                  <ModelTool
                    icon={BiExport}
                    onClickAction={() => {
                      unity?.sendMessage("ExportManager", "ExportGLB");
                    }}
                    name="Export"
                  />
                </Box>
              </Box>
            </Box>

            <Box
              ref={elementRef}
              onDoubleClick={() =>
                fullScreen
                  ? exitFullscreen(elementRef, setFullScreen)
                  : handleFullscreen(elementRef, setFullScreen)
              }
              sx={{
                width: fullScreen ? "100vw" : "60vw",
                // height: "100%",
                height: fullScreen
                  ? "100vh"
                  : { xl: "67vh", lg: "66vh", md: "64vh" },
              }}
              id="videoAnimation"
            >
              <Unity
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "0.8vw",
                }}
                devicePixelRatio={3}
                unityProvider={unity?.unityProvider}
              />
            </Box>
          </Box>

          <Box
            className="no-scroll"
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "70vh",
              width: "22%",
              p: 1,
              borderRadius: "0.8vw",
              background: "#121212",
              overflowY: "scroll",
            }}
          >
            <RenderItems items={panels.find((e) => e.selected)} />
          </Box>
        </Box>
      </Box>
      <FooterHome />
    </Box>
  );
}

export default Model3d;
